<template>
  <div class="knowledge">
    <Banner
      ref="banner"
      title="KNOWLEDGE"
      subtitle="專業知識"
      :img="bannerImg"
    />
    <PageNav
      ref="pageNav"
      :nav="knowledgeNav"
      :scrollActiveIdx="activeIdx"
      @updateNowNav="updateNowNav"
      :class="{ fixed: isPageNavFixed }"
      :style="`top: ${headerHeight - 1 || 0}px;`"
    />
    <div class="top">
      <div class="top_bg"></div>
      <div class="container w1400">
        <div
          v-for="(block, idx) in language.top"
          :key="`top_block_${idx}`"
          :ref="idx"
          class="block"
          :class="[
            `block-${idx + 1}`,
            (idx + 1) % 2 === 0 ? 'even' : 'odd'
          ]"
        >
          <div class="left">
            <div
              class="block_title"
              :class="[`scrollTarget-${idx + 1}`]"
            >
              <div class="large txt-red prefix-redline">{{ block.name_en }}</div>
              <div class="block_title_name h4 txt-main"
              :class="lang === 'TW'? 'suffix-redCube': ''">{{ block.name }}</div>
            </div>
            <p class="p">{{ block.content }}</p>
            <ul class="block_list list-redDot">
              <li
                v-for="(list, idx1) in block.list"
                :key="`list_${idx1}`"
                class="p"
              >{{ list }}</li>
            </ul>
          </div>
          <div class="right">
            <div class="pic_wrapper">
              <div
                class="pic_render"
                :style="{ backgroundImage: `url('${block.pic}')` }"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="container w1400">
        <div
          v-if="language.bottom && language.bottom.length"
          class="bottom_title h4 txt-center"
        >
          <span class="txt-main"
          :class="lang === 'TW'? 'suffix-redCube prefix-redCube': ''"
          >{{language.title}}</span>
        </div>
        <div
          v-for="(block, idx) in language.bottom"
          :key="`bottom_block_${idx}`"
          :ref="idx + 3"
          class="block"
          :class="[`block-${idx + 4}`]"
        >
          <div class="left">
            <div class="pic_wrapper">
              <div
                class="pic_render"
                :style="{ backgroundImage: `url('${block.pic}')` }"
              ></div>
            </div>
          </div>
          <div class="right">
            <div
              class="block_title"
              :class="[`scrollTarget-${idx + 4}`]"
            >
              <span class="prefix-redline h5">{{ block.name }}</span>
            </div>
            <ul class="block_list list-redDot">
              <li
                v-for="(list, idx1) in block.list"
                :key="`list_${idx1}`"
                class="p"
              >{{ list }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/scss/knowledge.scss';
import { knowledge, knowledgeEN } from '@/lib/const';
import bannerImg from '@/assets/imgs/banner/banner_knowledge.png';
import Banner from '@/components/banner/Index.vue';
import PageNav from '@/components/pageNav/Index.vue';
import publicMixin from '@/mixins/publicMixin';
import languageMixin from '@/mixins/languageMixin';

export default {
  name: 'Knowledge',
  mixins: [publicMixin, languageMixin],
  components: {
    Banner,
    PageNav,
  },
  data() {
    return {
      bannerHeight: 0,
      knowledge,
      knowledgeEN,
      bannerImg,
      isPageNavFixed: false,
      pageNavHeight: 0,
      knowledgeNav: [
        { name: 'HACCP' },
        { name: 'NSF' },
        { name: '鋼材介紹' },
        { name: '磨刀石使用方法' },
        { name: '磨刀棒使用方法' },
      ],
      activeIdx: 0,
      activeByScrollingDisable: false,
    };
  },
  computed: {
    headerHeight() {
      return this.$store.state.site.headerHeight;
    },
    language() {
      if (this.lang === 'TW') {
        return knowledge;
      }
      return knowledgeEN;
    },
  },
  methods: {
    updateNowNav(idx) {
      const setActiveByScrollingDisable = () => {
        this.activeByScrollingDisable = true;
        setTimeout(() => {
          this.activeByScrollingDisable = false;
        }, 1000);
      };
      const scrollToTarget = () => {
        const duration = 100;
        const ceiling = this.headerHeight + this.pageNavHeight + 10;
        this.scrollTo(`.scrollTarget-${idx + 1}`, duration, ceiling);
      };

      setActiveByScrollingDisable();
      scrollToTarget();
    },
    scrollHandler() {
      const togglePageNavFixedStatus = () => {
        if (window.scrollY > this.bannerHeight) {
          this.isPageNavFixed = true;
        } else {
          this.isPageNavFixed = false;
        }
      };
      const activePageNavBtnByScrolling = () => {
        const target1 = document.querySelector('.scrollTarget-1').getBoundingClientRect().top;
        const target2 = document.querySelector('.scrollTarget-2').getBoundingClientRect().top;
        const target3 = document.querySelector('.scrollTarget-3').getBoundingClientRect().top;
        const target4 = document.querySelector('.scrollTarget-4').getBoundingClientRect().top;
        const target5 = document.querySelector('.scrollTarget-5').getBoundingClientRect().top;

        const ceiling = this.headerHeight + this.pageNavHeight + 20;

        switch (true) {
          case (target1 <= ceiling && target2 > ceiling):
            this.activeIdx = 0;
            break;
          case (target2 <= ceiling && target3 > ceiling):
            this.activeIdx = 1;
            break;
          case (target3 <= ceiling && target4 > ceiling):
            this.activeIdx = 2;
            break;
          case (target4 <= ceiling && target5 > ceiling):
            this.activeIdx = 3;
            break;
          case (target5 <= ceiling):
            this.activeIdx = 4;
            break;
          default:
            this.activeIdx = 0;
            break;
        }
      };

      togglePageNavFixedStatus();
      if (this.activeByScrollingDisable) return;
      activePageNavBtnByScrolling();
    },
  },
  created() {
    window.addEventListener('scroll', this.scrollHandler);
  },
  mounted() {
    const initSize = () => {
      this.bannerHeight = this.$refs.banner.$el.clientHeight;
      this.pageNavHeight = this.$refs.pageNav.$el.getBoundingClientRect().height;
    };
    initSize();
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollHandler);
  },
  watch: {
  },
};
</script>
